<template>
  <div id="newFooter">
    <div class="container footer-wrapper">
      <div class="row rowcontainer">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12 footer-top-section">
          <!-- <div class="row rowcontainer">
            <div class="col-lg-2 col-md-2 col-sm-2 footer-navlinks"></div>
            <div class="col-lg-4 col-md-12 col-sm-12 top-text-section text-center">
              <h2 style="margin-bottom: 10px !important;">Exclusive Deals and Offers!</h2>
              <p style="color: gray;">Subscribe to our newsletter to receive special offers!</p>
            </div>
            <div class="col-lg-6 col-md-12 col-sm-12 text-center-md newsletter-wrapper">
              <div id="mc_embed_signup">
                <div id="mc_embed_signup_scroll">
                  <div>
                    <input
                      type="email"
                      value
                      name="EMAIL"
                      class="email form-control subscribe-txt"
                      id="mce-EMAIL"
                      placeholder
                      required
                    />
                    <button
                      @click="subscribe"
                      type="submit"
                      class="btn btn-primary subscribe-btn"
                      value="Subscribe"
                      name="subscribe"
                      id="mc-embedded-subscribe"
                    >Subscribe</button>
                  </div>

                  <div style="position: absolute; left: -5000px;" aria-hidden="true">
                    <input
                      type="text"
                      name="b_ea8c031f2e213143a959f1ff0_5c45a7f83a"
                      tabindex="-1"
                      value
                    />
                  </div>
                </div>
              </div>
            </div>
          </div> -->
          <hr style="border-top: 1px solid lightgrey;" />
        </div>
      </div>
      <div class="row rowcontainer">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12 footer-top-section">
          <div class="row rowcontainer">
            <div class="col-lg-2 col-md-3 col-sm-6 col-xs-12 footer-navlinks">
              <h2>
                <b>USEFUL LINKS</b>
              </h2>
              <ul>
                <li v-if="this.$store.state.buyer_accessToken !== null && this.merchantLog == undefined ">
                  <a @click="handleProfile">
                    My
                    Account
                  </a>
                </li>
                <li v-if="this.$store.state.buyer_accessToken === null && this.merchantLog == undefined " class="log login">
                  <a v-on:mouseup="loginNew" class="link-lg">
                    My
                    Account
                  </a>
                </li>
                <li>
                  <a @click="navigateToAboutUs">
                    About
                    Us
                  </a>
                </li>
                <li>
                  <a @click="navigateToContactUs">Contact Us</a>
                </li>
                <li>
                  <a href="./PrivacyPolicy.html" target="_blank">Privacy Policy</a>
                  <!-- <a @click="openPolicy">
                    <i class="fa fa-dot-circle-o" aria-hidden="true"></i> Privacy Policy
                  </a>-->
                </li>
                <li>
                  <a href="./TermsAndConditions.html" target="_blank">
                    Terms
                    and Conditions
                  </a>
                  <!-- <a @click="openTerms">
                    <i class="fa fa-dot-circle-o" aria-hidden="true"></i> Terms
                    And Conditions
                  </a>-->
                </li>
                <li>
                  <a href="./BuyerHelpCenterHome.html" target="_blank">
                    Help Center
                  </a>
                </li>
                <li>
                  <a href="https://www.slt.lk" target="_blank">Corporate Website</a>
                </li>
              </ul>
            </div>
            <div class="col-lg-4 col-md-3 col-sm-6 col-xs-12 contact-section">
              <h2>
                <b>CONTACT INFORMATION</b>
              </h2>
              <!-- <div class="address-wrapper">
                <div class="address-icon">
                  <i class="fa fa-map-marker" aria-hidden="true"></i>
                </div>
                <div
                  class="address-content"
                  style="color: gray; font-size: 14px;"
                >Sri Lanka Telecom PLC, Lotus Road, P.O.Box 503, Colombo 01,</div>
              </div> -->
              <div class="email-wrapper">
                <div class="email-icon">
                  <i class="fa fa-envelope" aria-hidden="true"></i>
                </div>
                <div class="email-content" style="color: gray; font-size: 14px;">info@cochchi.lk</div>
              </div>
              <!-- <div class="phone-wrapper">
                <div class="phone-icon">
                  <i class="fa fa-phone" aria-hidden="true"></i>
                </div>
                <div class="phone-content" style="color: gray; font-size: 14px;">0112 692 355</div>
              </div> -->
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 download-app">
              <h2>
                <b>DOWNLOAD THE APP</b>
              </h2>
              <div class="row">
                <div class="col-md-4 col-sm-6 col-xs-6 text-rigth-sm">
                  <a href="#" target="_blank">
                    <img src="/assets/image/playstore.png" class="store-img-playstore" />
                  </a>
                </div>
                <div class="col-md-4 col-sm-6 col-xs-6">
                  <a href="#" target="_blank">
                    <img src="https://gofitify.com/img/appgallery.png" class="store-img-huaweiAppgallery" />
                  </a>
                </div>
              </div>
              <!-- <div id="store-img-appstore-div-lg" class="row">
                <div class="col-md-4 col-sm-6 col-xs-6 text-rigth-sm">
                  <a href="#" target="_blank">
                    <img src="/assets/image/appstore.png" class="store-img-appstore" />
                  </a>
                </div>
              </div>
              <div id="store-img-appstore-div-sm" class="row">
                <div class="col-md-4 col-sm-3 col-xs-3">
                </div>
                <div class="col-md-4 col-sm-6 col-xs-6">
                  <a href="#" target="_blank">
                    <img src="/assets/image/appstore.png" class="store-img-appstore" />
                  </a>
                </div>
                <div class="col-md-4 col-sm-3 col-xs-3">
                </div>
              </div> -->
              <div class="row">
                <div class="col-md-8 col-sm-12 col-xs-12 text-rigth-sm">
                  <div class="box-service box-footer">
                        <div class="module clearfix">
                            <h2 style="margin-top: 25px !important;">
                              <b>Stay Connected</b>
                            </h2>
                            <div id="merfootericon" class="row" style="font-size:25px;">
                              <div class="col-md-3 col-sm-4 col-xs-4"><a class="social-links" href="https://www.facebook.com/cochchi/?hc_ref=ARSElkE1HasC78yVQVJTivh69s3cX2ZZQ0ocDJX6xtKnjqkfS9n3k9AAsItZRR3O4xw&fref=nf&__tn__=kC-R" target="_blank"><i class="fa fa-facebook"></i></a></div>
                              <div class="col-md-3 col-sm-4 col-xs-4"><a class="social-links" href="https://www.instagram.com/p/CRGfn4glwbW/" target="_blank"><i class="fa fa-instagram"></i></a></div>
                              <div class="col-md-3 col-sm-4 col-xs-4"><a class="social-links" href="https://www.youtube.com/channel/UCBIidMSwds9wf5AAmW_ABQQ" target="_blank"><i class="fa fa-youtube-play"></i></a></div>
                            </div>
                        </div>
                    </div>
                </div>
              </div>
            </div>
            <div class="col-lg-2 col-md-2 col-sm-12 col-xs-12 download-app">
              <img
                :src="this.$store.state.colourLogo"
                class="cochchiLogo"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid footer-wrapper">
      <div class="row rowcontainer">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12 footer-bottom-section">
          <span class="footer-copyright-text">©Cochchi.lk2021.All rights reserved, Cochchi.lk From SLT-Mobitel, Platform partner Supreme</span>
        </div>
      </div>
    </div>
    <BuyerLoginModal ref="active" />
  </div>
</template>
<script>
import UncommonFeatures from "@/components/common/uncommon_features.vue";
import BuyerLoginModal from "@/views/Buyer/BuyerLogin/BuyerLloginModal.vue";
export default {
  name: "BottomFooter",
  components: {
    UncommonFeatures,
    BuyerLoginModal
  },
  data() {
    return {
      userRole: 1,
      merchantLog: localStorage.merchant_token,
    };
  },
  computed: {},
  methods: {
    navigateToContactUs() {
      this.$router.push("/contact-us");
    },
    navigateToAboutUs() {
      this.$router.push("/about-us");
    },
    openTerms() {
      // window.open("https://drive.google.com/file/d/1UkRyecMnkWK1yVBbTRMBzCXhCx_SEZM3/view?usp=sharing");
      //          var myWindow = window.open("", "MsgWindow", "width=500,height=600");
      //   myWindow.document.write();
      this.$router.push({ name: "TermsAndConditions" });
    },
    subscribe() {
      this.$router.push({ name: "Subscribe" });
    },
    loginNew() {
      this.$refs.active.activetab = 1;
      $("#BuyerLoginModal").modal("show");
      if (this.$refs.active.showforgotpass === true) {
        // hide forget password modal when login
        this.$refs.active.showforgotpass = false;
      }
    },
    init() {
      if (localStorage.getItem("buyer_data")) {
        let user = "";
        user = JSON.parse(localStorage.getItem("buyer_data"));
        if (user.first_name !== null && user.last_name !== null) {
          this.buyer = user.first_name + " " + user.last_name;
        }
        this.userRole = user.user_role != undefined ? user.user_role : "";
      } else {
        this.getLoginInfo();
      }
    },
    handleProfile() {
      if (!localStorage.buyer_data) {
        this.$router.push("/buyer-profile").catch(err => {});
        return;
      }
      if (this.$store.state.buyer_accessToken === null) {
        this.loginNew();
      }
      let user = JSON.parse(localStorage.getItem("buyer_data"));
      this.userRole = user.user_role != undefined ? user.user_role : "";
      if (this.userRole == 2) {
        this.$router.push("/buyer-orders").catch(err => {});
        return;
      } else {
        this.$router.push("/buyer-profile").catch(err => {});
      }
    },
    openPolicy() {
      this.$router.push({ name: "PrivacyPolicy" });
      // window.open("https://drive.google.com/file/d/1wCkyNZ6emXvzdZuNy_yGSmCj6UG3B0py/view?usp=sharing");
    },
    goToPageTop: function() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    }
  }
};
</script>
<style scoped>
.rowcontainer {
  margin-left: 0px;
  margin-right: 0px;
}
#newFooter .footer-wrapper {
  height: auto;
  padding-left: 0px;
  padding-right: 0px;
}
#newFooter .footer-wrapper h2 {
  font-size: 20px;
  font-weight: 500;
  color: #212529;
  margin-bottom: 25px;
}

.top-text-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* Back to top styles */
#newFooter .footer-wrapper .back-to-pagetop {
  min-height: 75px;
  background: #5779ae;
  text-align: center;
  color: #ffffff;
  line-height: 75px;
  font-size: 14px;
}
#newFooter .footer-wrapper .back-to-pagetop a {
  color: #ffffff;
  display: block;
  line-height: 75px;
}
#newFooter .footer-wrapper .back-to-pagetop a:hover {
  text-decoration: none;
  color: #000000;
}

.social-links {
  color: #000000 !important;
}

.social-links:hover {
  color: #0d56a6 !important;
}

#newFooter .footer-wrapper .back-to-pagetop a:focus {
  text-decoration: none;
  color: #0d56a6;
}
/*Footer top styles */
#newFooter .footer-wrapper .footer-top-section {
  padding-top: 30px;
  padding-bottom: 30px;
  color: #212529;
}
#newFooter .footer-wrapper .footer-top-section .footer-navlinks ul {
  list-style: none;
}
#newFooter .footer-wrapper .footer-top-section .footer-navlinks ul li {
  margin-bottom: 5px;
}
#newFooter .footer-wrapper .footer-top-section .footer-navlinks ul li a {
  color: gray;
  font-size: 14px;
}
#newFooter .footer-wrapper .footer-top-section .footer-navlinks ul li a:hover {
  color: #0d56a6;
}


#newFooter
  .footer-wrapper
  .footer-top-section
  .contact-section
  .address-wrapper {
  display: flex;
  flex-wrap: wrap;
}
#newFooter
  .footer-wrapper
  .footer-top-section
  .contact-section
  .address-wrapper
  .address-icon {
  flex-basis: 5%;
}
#newFooter
  .footer-wrapper
  .footer-top-section
  .contact-section
  .address-wrapper
  .address-content {
  flex-basis: 95%;
}
#newFooter .footer-wrapper .footer-top-section .contact-section .email-wrapper {
  display: flex;
  margin-top: 12px;
}
#newFooter
  .footer-wrapper
  .footer-top-section
  .contact-section
  .email-wrapper
  .email-icon {
  flex-basis: 5%;
}
#newFooter
  .footer-wrapper
  .footer-top-section
  .contact-section
  .email-wrapper
  .email-content {
  flex-basis: 95%;
}
#newFooter .footer-wrapper .footer-top-section .contact-section .phone-wrapper {
  display: flex;
  margin-top: 12px;
}
#newFooter
  .footer-wrapper
  .footer-top-section
  .contact-section
  .phone-wrapper
  .phone-icon {
  flex-basis: 5%;
}
#newFooter
  .footer-wrapper
  .footer-top-section
  .contact-section
  .phone-wrapper
  .phone-content {
  flex-basis: 95%;
}
#newFooter .footer-wrapper .footer-top-section .newsletter-wrapper {
  padding-bottom: 20px;
  /* background: url("/assets/image/new-footer-bg.png") no-repeat bottom; */
}
#newFooter
  .footer-wrapper
  .footer-top-section
  .newsletter-wrapper
  .newsletter-input-block {
  margin-top: 20px;
}
#newFooter
  .footer-wrapper
  .footer-top-section
  .newsletter-wrapper
  .newsletter-input-block
  button {
  margin-top: 10px;
  width: 100%;
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  border-radius: 4px;
}

/*Footer middle styles */
#newFooter .footer-wrapper .footer-middle-section {
  padding-top: 20px;
  padding-bottom: 20px;
  margin-top: -245px;
}
#newFooter .footer-wrapper .footer-middle-section .call-section img {
  display: block;
  width: 25%;
  padding-left: 2%;
  padding-right: 2%;
  /* background: #000000; */
  border-radius: 40px;
  margin: 0 auto;
  text-align: left;
  color: #ffffff;
}
/* #newFooter .footer-wrapper .footer-middle-section .call-section button span{
    display: block;
} */
/* #newFooter .footer-wrapper .footer-middle-section .call-section button span.online-sup{
    font-size: 16px;
    font-weight: 400;
} */
/* #newFooter .footer-wrapper .footer-middle-section .call-section button span.number{
    font-size: 24px;
    font-weight: 800;
    margin-top: 4px;
    margin-bottom: 4px;
} */
/* #newFooter .footer-wrapper .footer-middle-section .call-section button span.call-free{
    font-size: 11px;
    font-weight: 400;
    color: #808080;
} */
#newFooter .footer-wrapper .footer-middle-section .social-media-block {
  text-align: center;
  padding-top: 20px;
  padding-bottom: 15px;
}
#newFooter .footer-wrapper .footer-middle-section .social-media-block .fa {
  font-size: 28px;
  margin-left: 6px;
  margin-right: 6px;
  color: #000000;
}
#newFooter
  .footer-wrapper
  .footer-middle-section
  .social-media-block
  .fa:hover {
  color: #0d56a6;
}
#newFooter .footer-wrapper .footer-middle-section .social-media-block a {
  color: #000000;
}
#newFooter .footer-wrapper .footer-middle-section .social-media-block a:hover {
  color: #0d56a6;
}
/*Footer bottom styles */
#newFooter .footer-wrapper .footer-bottom-section {
  min-height: 50px;
  background: linear-gradient(98deg, rgba(0,104,181,1) 0%, rgba(20,84,161,1) 33%, rgba(228,109,42,1) 88%, rgba(250,179,11,1) 100%);
  text-align: center;
  color: #ffffff;
  line-height: 50px;
  font-size: 14px;
}

.address-icon {
  font-size: 18px;
  color: rgb(0, 91, 170);
}

.email-icon {
  font-size: 18px;
  color: rgb(0, 91, 170);
}

.phone-icon {
  font-size: 18px;
  color: rgb(0, 91, 170);
}

#newFooter .footer-wrapper .footer-bottom-section a {
  color: #ffffff;
  font-size: 14px;
}
#newFooter .footer-wrapper .footer-bottom-section a {
  text-decoration: none;
}
.supiripola-log-in-sathosa {
  display: inline-block;
  width: 7.5%;
  margin-left: 5px;
  margin-right: 5px;
}
.slt-logo-insathosa {
  display: inline-block;
  width: 2%;
  margin-left: 5px;
  margin-right: 5px;
}
.subscribe-btn {
  display: inline-block !important;
  background: rgb(0, 91, 170);
  border: none;
  height: 50px;
  border-radius: 6px;
  width: 20%;
  margin-top: -2px;
}

.subscribe-txt {
  width: 50%;
  display: inline-block !important;
  height: 50px;
  border-radius: 6px;
}

#store-img-appstore-div-lg {
  display: block;
  margin-top: 20px !important;
}

#store-img-appstore-div-sm {
  display: none;
  /* margin-top: 20px !important; */
}

.subscribe-btn:hover {
  color: #000000;
}
/* Media queries */
@media screen and (max-width: 1200px) {
  #newFooter .footer-wrapper .footer-middle-section .call-section img {
    width: 30%;
  }
  #newFooter
    .footer-wrapper
    .footer-top-section
    .contact-section
    .address-wrapper
    .address-icon {
    flex-basis: 10%;
  }
  #newFooter
    .footer-wrapper
    .footer-top-section
    .contact-section
    .address-wrapper
    .address-content {
    flex-basis: 90%;
  }
  #newFooter
    .footer-wrapper
    .footer-top-section
    .contact-section
    .email-wrapper
    .email-icon {
    flex-basis: 10%;
  }
  #newFooter
    .footer-wrapper
    .footer-top-section
    .contact-section
    .email-wrapper
    .email-content {
    flex-basis: 90%;
  }
  #newFooter
    .footer-wrapper
    .footer-top-section
    .contact-section
    .phone-wrapper
    .phone-icon {
    flex-basis: 10%;
  }
  #newFooter
    .footer-wrapper
    .footer-top-section
    .contact-section
    .phone-wrapper
    .phone-content {
    flex-basis: 90%;
  }
  #newFooter .footer-wrapper .footer-top-section .newsletter-wrapper {
    padding-right: 0px;
  }

  .newsletter-wrapper {
    text-align: center;
  }
}
@media screen and (max-width: 991px) {
  #newFooter .footer-wrapper .footer-middle-section .call-section img {
    width: 35%;
  }
  .supiripola-log-in-sathosa {
    width: 8%;
  }

  .slt-logo-insathosa {
    width: 2.75%;
  }

  .download-app {
    text-align: center;
    margin: 20px 0px;
  }

  .cochchiLogo {
    width: 50% !important;
  }

  .store-img-playstore {
    float: right;
  }

  #store-img-appstore-div-lg {
    display: none;
    margin-top: 20px !important;
  }

  #store-img-appstore-div-sm {
    display: block;
    margin-top: 20px !important;
  }

  .store-img-huaweiAppgallery {
    float: left;
    max-width: 50%;
  }
}
@media screen and (max-width: 875px) {
  #newFooter .footer-wrapper .footer-middle-section .call-section img {
    width: 39%;
  }
}
@media screen and (max-width: 768px) {
  #newFooter
    .footer-wrapper
    .footer-top-section
    .contact-section
    .address-wrapper
    .address-icon {
    flex-basis: 5%;
  }
  #newFooter
    .footer-wrapper
    .footer-top-section
    .contact-section
    .address-wrapper
    .address-content {
    flex-basis: 95%;
  }
  #newFooter
    .footer-wrapper
    .footer-top-section
    .contact-section
    .email-wrapper
    .email-icon {
    flex-basis: 5%;
  }
  #newFooter
    .footer-wrapper
    .footer-top-section
    .contact-section
    .email-wrapper
    .email-content {
    flex-basis: 95%;
  }
  #newFooter
    .footer-wrapper
    .footer-top-section
    .contact-section
    .phone-wrapper
    .phone-icon {
    flex-basis: 5%;
  }
  #newFooter
    .footer-wrapper
    .footer-top-section
    .contact-section
    .phone-wrapper
    .phone-content {
    flex-basis: 95%;
  }
  #newFooter .footer-wrapper .footer-top-section .contact-section {
    margin-bottom: 35px;
  }
  #newFooter .footer-wrapper .footer-top-section .footer-navlinks {
    margin-bottom: 35px;
  }
  #newFooter .footer-wrapper .footer-top-section .newsletter-wrapper {
    padding-left: 15px;
    padding-right: 15px;
  }
  #newFooter .footer-wrapper .footer-middle-section {
    margin-top: -110px;
  }
  #newFooter .footer-wrapper .footer-middle-section .call-section img {
    width: 42%;
    padding-left: 4%;
    padding-right: 4%;
  }

  .store-img-huaweiAppgallery {
    max-width: 170px;
  }
}
@media screen and (max-width: 725px) {
  #newFooter .footer-wrapper .footer-bottom-section {
    line-height: 16px;
    font-size: 13px;
  }
  .footer-supiri-logo {
    display: block;
    padding-top: 25px;
    width: 100%;
  }
  .footer-supiri-logo img {
    width: 30%;
  }
  .footer-slt-logo {
    display: block;
    width: 100%;
  }
  .footer-slt-logo img {
    width: 10%;
    padding-bottom: 20px;
  }
  .footer-copyright-text {
    display: block;
    width: 100%;
    padding-top: 15px;
    padding-bottom: 15px;
  }
}
@media screen and (max-width: 550px) {
  #newFooter .footer-wrapper .footer-middle-section .call-section img {
    width: 50%;
    padding-left: 4%;
    padding-right: 4%;
  }

  #newFooter .subscribe-btn {
    display: block;
    background: rgb(0, 91, 170);
    border: none;
    height: 50px;
    border-radius: 6px;
    width: 100%;
    margin-top: 10px;
  }

  .subscribe-txt {
    width: 100% !important;
    display: inline-block !important;
    height: 50px;
    border-radius: 6px;
    margin: 0% !important;
  }
}
@media screen and (max-width: 425px) {
  #newFooter
    .footer-wrapper
    .footer-top-section
    .contact-section
    .address-wrapper
    .address-icon {
    flex-basis: 10%;
  }
  #newFooter
    .footer-wrapper
    .footer-top-section
    .contact-section
    .address-wrapper
    .address-content {
    flex-basis: 90%;
  }
  #newFooter
    .footer-wrapper
    .footer-top-section
    .contact-section
    .email-wrapper
    .email-icon {
    flex-basis: 10%;
  }
  #newFooter
    .footer-wrapper
    .footer-top-section
    .contact-section
    .email-wrapper
    .email-content {
    flex-basis: 90%;
  }
  #newFooter
    .footer-wrapper
    .footer-top-section
    .contact-section
    .phone-wrapper
    .phone-icon {
    flex-basis: 10%;
  }
  #newFooter
    .footer-wrapper
    .footer-top-section
    .contact-section
    .phone-wrapper
    .phone-content {
    flex-basis: 90%;
  }
  #newFooter .footer-wrapper .footer-middle-section .call-section img {
    width: 50%;
    padding-left: 4%;
    padding-right: 4%;
  }
}
@media screen and (max-width: 375px) {
  .store-img-huaweiAppgallery {
    max-width: 100%;
  }

  #newFooter .footer-wrapper .footer-middle-section .call-section img {
    width: 75%;
    padding-left: 7%;
    padding-right: 7%;
  }
}
</style>
