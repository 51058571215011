var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"newFooter"}},[_c('div',{staticClass:"container footer-wrapper"},[_vm._m(0),_c('div',{staticClass:"row rowcontainer"},[_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12 footer-top-section"},[_c('div',{staticClass:"row rowcontainer"},[_c('div',{staticClass:"col-lg-2 col-md-3 col-sm-6 col-xs-12 footer-navlinks"},[_vm._m(1),_c('ul',[(this.$store.state.buyer_accessToken !== null && this.merchantLog == undefined )?_c('li',[_c('a',{on:{"click":_vm.handleProfile}},[_vm._v(" My Account ")])]):_vm._e(),(this.$store.state.buyer_accessToken === null && this.merchantLog == undefined )?_c('li',{staticClass:"log login"},[_c('a',{staticClass:"link-lg",on:{"mouseup":_vm.loginNew}},[_vm._v(" My Account ")])]):_vm._e(),_c('li',[_c('a',{on:{"click":_vm.navigateToAboutUs}},[_vm._v(" About Us ")])]),_c('li',[_c('a',{on:{"click":_vm.navigateToContactUs}},[_vm._v("Contact Us")])]),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5)])]),_vm._m(6),_vm._m(7),_c('div',{staticClass:"col-lg-2 col-md-2 col-sm-12 col-xs-12 download-app"},[_c('img',{staticClass:"cochchiLogo",attrs:{"src":this.$store.state.colourLogo}})])])])])]),_vm._m(8),_c('BuyerLoginModal',{ref:"active"})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row rowcontainer"},[_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12 footer-top-section"},[_c('hr',{staticStyle:{"border-top":"1px solid lightgrey"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h2',[_c('b',[_vm._v("USEFUL LINKS")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"./PrivacyPolicy.html","target":"_blank"}},[_vm._v("Privacy Policy")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"./TermsAndConditions.html","target":"_blank"}},[_vm._v(" Terms and Conditions ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"./BuyerHelpCenterHome.html","target":"_blank"}},[_vm._v(" Help Center ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"https://www.slt.lk","target":"_blank"}},[_vm._v("Corporate Website")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-4 col-md-3 col-sm-6 col-xs-12 contact-section"},[_c('h2',[_c('b',[_vm._v("CONTACT INFORMATION")])]),_c('div',{staticClass:"email-wrapper"},[_c('div',{staticClass:"email-icon"},[_c('i',{staticClass:"fa fa-envelope",attrs:{"aria-hidden":"true"}})]),_c('div',{staticClass:"email-content",staticStyle:{"color":"gray","font-size":"14px"}},[_vm._v("info@cochchi.lk")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-4 col-md-4 col-sm-12 col-xs-12 download-app"},[_c('h2',[_c('b',[_vm._v("DOWNLOAD THE APP")])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4 col-sm-6 col-xs-6 text-rigth-sm"},[_c('a',{attrs:{"href":"#","target":"_blank"}},[_c('img',{staticClass:"store-img-playstore",attrs:{"src":"/assets/image/playstore.png"}})])]),_c('div',{staticClass:"col-md-4 col-sm-6 col-xs-6"},[_c('a',{attrs:{"href":"#","target":"_blank"}},[_c('img',{staticClass:"store-img-huaweiAppgallery",attrs:{"src":"https://gofitify.com/img/appgallery.png"}})])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-8 col-sm-12 col-xs-12 text-rigth-sm"},[_c('div',{staticClass:"box-service box-footer"},[_c('div',{staticClass:"module clearfix"},[_c('h2',{staticStyle:{"margin-top":"25px !important"}},[_c('b',[_vm._v("Stay Connected")])]),_c('div',{staticClass:"row",staticStyle:{"font-size":"25px"},attrs:{"id":"merfootericon"}},[_c('div',{staticClass:"col-md-3 col-sm-4 col-xs-4"},[_c('a',{staticClass:"social-links",attrs:{"href":"https://www.facebook.com/cochchi/?hc_ref=ARSElkE1HasC78yVQVJTivh69s3cX2ZZQ0ocDJX6xtKnjqkfS9n3k9AAsItZRR3O4xw&fref=nf&__tn__=kC-R","target":"_blank"}},[_c('i',{staticClass:"fa fa-facebook"})])]),_c('div',{staticClass:"col-md-3 col-sm-4 col-xs-4"},[_c('a',{staticClass:"social-links",attrs:{"href":"https://www.instagram.com/p/CRGfn4glwbW/","target":"_blank"}},[_c('i',{staticClass:"fa fa-instagram"})])]),_c('div',{staticClass:"col-md-3 col-sm-4 col-xs-4"},[_c('a',{staticClass:"social-links",attrs:{"href":"https://www.youtube.com/channel/UCBIidMSwds9wf5AAmW_ABQQ","target":"_blank"}},[_c('i',{staticClass:"fa fa-youtube-play"})])])])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid footer-wrapper"},[_c('div',{staticClass:"row rowcontainer"},[_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12 footer-bottom-section"},[_c('span',{staticClass:"footer-copyright-text"},[_vm._v("©Cochchi.lk2021.All rights reserved, Cochchi.lk From SLT-Mobitel, Platform partner Supreme")])])])])
}]

export { render, staticRenderFns }